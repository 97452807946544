import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

const HeroSection = ({ children, className, threshold = 1 }) => {
  const { ref, inView } = useInView({
    threshold,
  });

  const [wasVisible, setWasVisible] = useState(false);

  useEffect(() => {
    if (!wasVisible && inView) {
      setWasVisible(true);
    }
  }, [inView, wasVisible]);

  return (
    <div
      className={`parallax-hero-section${className ? ` ${className}` : ''}`}
    >
      <div
        ref={ref}
        className={`parallax-hero-section-inner${wasVisible ? ' visible' : ''}`}
      >
        {children}
      </div>
    </div>
  );
};

export default HeroSection;
