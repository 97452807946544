import React from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import { getImage } from "gatsby-plugin-image"

import useWindowVerticalScroll from '../utils/useWindowVerticalScroll';
import HeroSection from './HeroSection';

const Hero = ({ data }) => {
  const pageScroll = useWindowVerticalScroll();
  const leftImage = getImage(data?.imageLeft?.localFile);
  const rightImage = getImage(data?.imageRight?.localFile);

  const intl = useIntl();
  const { locale } = intl;

  return (
    <div className="parallax-hero-wrapper">
      <div className="parallax-sticky-element">
        <div className="parallax-hero-content-wrapper">
          <div
            className="theater-reveal left"
            style={{
              transform: `translateX(-${pageScroll / 2}px)`,
              backgroundImage: `url(${data?.imageLeft?.localFile?.url}), url(${leftImage?.placeholder?.fallback})`,
              backgroundPositionY: `${
                pageScroll * 0.05 < 100 ? pageScroll * 0.05 : 100
              }%`,
              borderRight:
                pageScroll === 0
                  ? '1px var(--menu-border-color) solid'
                  : 'none',
            }}
          >
            <div
              className={`parallax-hero-divider${
                pageScroll === 0 ? '' : ' hide'
              }`}
            />
          </div>
          <div
            className="theater-reveal right"
            style={{
              transform: `translateX(${pageScroll / 2}px)`,
              backgroundImage: `url(${data?.imageRight?.localFile?.url}), url(${rightImage?.placeholder?.fallback})`,
              backgroundPositionY: `${
                pageScroll * 0.05 < 100 ? pageScroll * 0.05 : 100
              }%`,
            }}
          >
            <div
              className={`parallax-hero-divider${
                pageScroll === 0 ? '' : ' hide'
              }`}
            />
          </div>
        </div>
      </div>
      <HeroSection>
        <div className="parallax-hero-hello">
          {locale === 'en' && data?.firstMessageEn
            ? data.firstMessageEn
            : data.firstMessageFr}
        </div>
        <div className="parallax-hero-hello second">
          <a href={data?.secondMessageUrl} target="_blank" rel='noreferrer'>
            {locale === 'en' && data?.secondMessageEn
              ? data.secondMessageEn
              : data.secondMessageFr}
          </a>
        </div>
        <div className="title-separator" />
      </HeroSection>
    </div>
  );
};

export default Hero;
