import React, { useState, useEffect } from 'react';
import { Parallax } from 'react-scroll-parallax';
import { useInView } from 'react-intersection-observer';
import { useIntl } from 'gatsby-plugin-react-intl';

import ArrowIcon from '../assets/icons/arrow-next.svg';
import Link from './Link';

const HomeSection = ({ section }) => {
  const intl = useIntl();
  const { locale } = intl;

  const { ref, inView } = useInView({
    threshold: 0.3,
  });

  const [wasVisible, setWasVisible] = useState(false);

  useEffect(() => {
    if (!wasVisible && inView) {
      setWasVisible(true);
    }
  }, [inView, wasVisible]);

  return (
    <div className={`home-section${wasVisible ? ' animate' : ''}`} ref={ref}>
      {(section?.sectionSettings?.sectionType === 'title' && (
        <div className="home-centered-title-section">
          {(section?.sectionSettings?.goToPage && (
            <a
              href={section?.sectionSettings?.goToPage}
              target="_blank"
              rel="noreferrer"
            >
              <div>
                {locale === 'en' && section?.sectionSettings?.titleEn
                  ? section.sectionSettings.titleEn
                  : section?.sectionSettings?.titleFr}
              </div>
            </a>
          )) || (
            <div>
              {locale === 'en' && section?.sectionSettings?.titleEn
                ? section.sectionSettings.titleEn
                : section?.sectionSettings?.titleFr}
            </div>
          )}
          <div className="title-separator" />
        </div>
      )) || (
        <>
          <div className="home-two-columns-section">
            <div className="home-static-title home-inverted-title">
              {locale === 'en' && section?.sectionSettings?.titleEn
                ? section.sectionSettings.titleEn
                : section?.sectionSettings?.titleFr}
            </div>
            <Parallax
              translateX={
                section?.sectionSettings?.thumbnailPosition === 'left'
                  ? [-10, 60]
                  : [90, 20]
              }
            >
              <div className="home-parallax-title home-inverted-title">
                {locale === 'en' && section?.sectionSettings?.titleEn
                  ? section.sectionSettings.titleEn
                  : section?.sectionSettings?.titleFr}
              </div>
            </Parallax>
            <div
              className={`home-columns-containers${
                section?.sectionSettings?.thumbnailPosition === 'left'
                  ? ''
                  : ' inverted'
              }`}
            >
              <div
                className={`home-section-image${
                  section?.sectionSettings?.parallaxDirection ===
                    'horizontal' ||
                  section?.sectionSettings?.parallaxDirection === 'vertical'
                    ? ' parallax'
                    : ''
                }`}
              >
                {(section?.sectionSettings?.goToPage && (
                  <Link to={section?.sectionSettings?.goToPage}>
                    <img
                      className={`non-parallax-image${
                        !section?.sectionSettings?.parallaxDirection ||
                        section?.sectionSettings?.parallaxDirection === 'none'
                          ? ' display'
                          : ' hide'
                      }`}
                      src={section?.sectionSettings?.thumbnail?.sourceUrl}
                      width="100%"
                      alt=""
                    />
                  </Link>
                )) || (
                  <img
                    className={`non-parallax-image${
                      !section?.sectionSettings?.parallaxDirection ||
                      section?.sectionSettings?.parallaxDirection === 'none'
                        ? ' display'
                        : ' hide'
                    }`}
                    src={section?.sectionSettings?.thumbnail?.sourceUrl}
                    width="100%"
                    alt=""
                  />
                )}
                {section?.sectionSettings?.parallaxDirection &&
                  section?.sectionSettings?.parallaxDirection !== 'none' && (
                    <div className="parallax-image-container">
                      {(section?.sectionSettings?.parallaxDirection ===
                        'horizontal' && (
                        <>
                          <Parallax
                            translateX={
                              section?.sectionSettings?.thumbnailPosition ===
                              'left'
                                ? [0, -50]
                                : [-50, 0]
                            }
                          >
                            <Link to={section?.sectionSettings?.goToPage}>
                              <img
                                src={
                                  section?.sectionSettings?.thumbnail?.sourceUrl
                                }
                                alt=""
                              />
                            </Link>
                          </Parallax>
                          <div className="horizontal-parallax-overlay">
                            <div className="left" />
                            <div className="right" />
                          </div>
                        </>
                      )) || (
                        <>
                          <Parallax translateY={[0, -50]}>
                            <div className="vertical-parallax-image-container">
                              <Link to={section?.sectionSettings?.goToPage}>
                                <img
                                  style={{
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                  }}
                                  src={
                                    section?.sectionSettings?.thumbnail
                                      ?.sourceUrl
                                  }
                                  alt=""
                                />
                              </Link>
                            </div>
                          </Parallax>
                          <div className="vertical-parallax-overlay">
                            <div className="top" />
                            <div className="bottom" />
                          </div>
                        </>
                      )}
                    </div>
                  )}
              </div>
              <div
                className={`home-section-text vertical-parallax-section${
                  section?.sectionSettings?.parallaxDirection ===
                    'horizontal' ||
                  section?.sectionSettings?.parallaxDirection === 'vertical'
                    ? ' parallax'
                    : ''
                }`}
              >
                {locale === 'en' && section?.sectionSettings?.contentEn
                  ? section.sectionSettings.contentEn
                  : section?.sectionSettings?.contentFr}
              </div>
              <div className="home-section-text mobile">
                {locale === 'en' && section?.sectionSettings?.contentEn
                  ? section.sectionSettings.contentEn
                  : section?.sectionSettings?.contentFr}
              </div>
            </div>
          </div>
          <Link
            to={section?.sectionSettings?.goToPage || '#'}
            className={`home-section-button${
              section?.sectionSettings?.thumbnailPosition === 'left'
                ? ''
                : ' inverted'
            }`}
          >
            {locale === 'en' && section?.sectionSettings?.buttonTextEn
              ? section.sectionSettings.buttonTextEn
              : section?.sectionSettings?.buttonTextFr}
            <ArrowIcon />
          </Link>
        </>
      )}
    </div>
  );
};

export default HomeSection;
