import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useIntl } from 'gatsby-plugin-react-intl';

import HomeSection from '../components/HomeSection';
import Hero from '../components/HeroTwo';
import LayoutComponent from '../components/Layout';
import Seo from '../components/Seo';

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query homeQuery {
      allWpHero {
        nodes {
          heroContent {
            fieldGroupName
            firstMessageEn
            firstMessageFr
            secondMessageEn
            secondMessageFr
            secondMessageUrl
            imageLeft {
              localFile {
                url
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
            imageRight {
              localFile {
                url
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
          }
        }
      }
      allWpHomeSection {
        nodes {
          id
          sectionSettings {
            order
            sectionType
            buttonTextFr
            buttonTextEn
            titleFr
            titleEn
            thumbnail {
              sourceUrl
            }
            thumbnailPosition
            parallaxDirection
            fieldGroupName
            contentFr
            contentEn
            goToPage
          }
          title
        }
      }
    }
  `);

  const intl = useIntl();

  const sections =
    data?.allWpHomeSection?.nodes?.sort(
      (a, b) => a.sectionSettings.order - b.sectionSettings.order
    ) || [];

  return (
    <LayoutComponent showNewsLetter>
      <Seo title={intl.formatMessage({ id: 'general.home' })} />
      <Hero
        data={
          data?.allWpHero?.nodes.length !== 0
            ? data.allWpHero.nodes[0].heroContent
            : null
        }
      />
      {sections.map(section => (
        <HomeSection section={section} key={section?.id} />
      ))}
    </LayoutComponent>
  );
};

export default IndexPage;
